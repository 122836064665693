import React from 'react';
import styled from 'styled-components';

import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 80vh;
  background-color: #C84836;
  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-template-rows: 100%;
  
  img {
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }

  .left-img {
    grid-column: 1;
    grid-row: 1;
  }

  .left-caption {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    color: white;
    font-family: 'Lato';
    margin-left: 107px;
    margin-bottom: 95px;
    font-size: 20px;
    letter-spacing: 1px;

    @media (max-width: 1360px) {
      font-size: 18px;
    }
  }

  .right-img {
    grid-column: 2;
    grid-row: 1;
    max-height: 80%;
    max-width: 80%;
  }

  .right-caption {
    grid-column: 2;
    grid-row: 1;
    align-self: end;
    color: white;
    font-family: 'Lato';
    margin-left: 107px;
    margin-bottom: 95px;
    font-size: 20px;
    letter-spacing: 1px;

    @media (max-width: 1360px) {
      font-size: 18px;
    }
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #C84836;

    img {
    max-width: 90%;
    max-height: 90%;
  }

  .left-caption {
    color: white;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 1px;
  }

  .right-img {
    max-height: 90%;
    max-width: 90%;
  }

  .right-caption {
    color: white;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 1px;
  }
`;

const SplitImageOrangBG = ({ leftImage, rightImage, leftCaptionWhite, leftCaptionBlack, rightCaptionWhite, rightCaptionBlack }) => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img className="left-img" src={leftImage} />
        <div className="left-caption">{leftCaptionWhite}<span style={{ color: '#212028' }}>{leftCaptionBlack}</span></div>
        <img className="right-img" src={rightImage} />
        <div className="right-caption">{rightCaptionWhite}<span style={{ color: '#212028' }}>{rightCaptionBlack}</span></div>
      </MobileContainer>
    ) : (
        <Container>
          <img className="left-img" src={leftImage} />
          <div className="left-caption">{leftCaptionWhite}<span style={{ color: '#212028' }}>{leftCaptionBlack}</span></div>
          <img className="right-img" src={rightImage} />
          <div className="right-caption">{rightCaptionWhite}<span style={{ color: '#212028' }}>{rightCaptionBlack}</span></div>
        </Container >
      )
  );
}

export default SplitImageOrangBG;