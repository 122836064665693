import React from 'react';
import Layout from '../../components/layout';
import { Helmet } from 'react-helmet';

import BusinessmanSectionOne from '../../components/bio/GradiantImage';
import SectionOneBackground from '../../images/businessman-bg.png';
import SectionOneMobileBackground from '../../images/mobile/Businessman.png'

import BusinessmanSectionTwo from '../../components/bio/ImageLeftWhiteBGContainer';
import SectionTwoImage from '../../images/businessman_sec2.png';

import BusinessmanSectionThree from '../../components/bio/SplitImageOrangeBG';
import SectionThreeLeftImage from '../../images/businessman_sec3_1_2.png';
import SectionThreeRightImage from '../../images/businessman_sec3_2_2.png';

import BusinessmanSectionFour from '../../components/bio/BottomSectionContainer';
import SectionFourImage from '../../images/businessman_sec4.png';

const Businessman = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: Businessman</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <BusinessmanSectionOne
          title={'Businessman'}
          text={`Bill Armstrong was a highly successful businessman. Starting in radio, he broadened his family's portfolio into newspaper publishing, television broadcasting, and eventually the financial services and real estate businesses. In the 1960s and 70s he was on the boards of People’s Bank and Trust, People’s Bank of Arapahoe County, and Intermountain Network. In 1990, he purchased a small mortgage firm, Cherry Creek Mortgage Company, in partnership with his son, Wil. It grew quickly as a mortgage banking firm, twice recognized by Inc. Magazine as one of the 500 fastest growing companies in America. He eventually owned four mortgage banking firms, a title insurance company, an appraisal company, a real estate business, and (also in partnership with Wil) a software development firm.`}
          background={SectionOneBackground}
          mobileBackground={SectionOneMobileBackground}
        />
        <BusinessmanSectionTwo
          image={SectionTwoImage}
          captionBlack={'Armstrong and the board of the Denver-based Oppenheimer Funds'}
          text={'He was Chairman of the Denver-based Oppenheimer Funds, and served on the boards of several public companies, including Helmerich and Payne, the world’s largest on-shore drilling company, Storage Technology Corp., Natec, and Provident Companies, a Fortune 500 insurance group. He was on the board of the Christian Broadcasting Network’s Family Channel, and on various non-profit boards, such as the Free Congress Foundation, the Council for National Policy, and the Investment Company Institute, the global association of regulated funds. He also founded and directed the Armstrong Family Foundation.'}
        />
        <BusinessmanSectionThree
          leftImage={SectionThreeLeftImage}
          rightImage={SectionThreeRightImage}
          leftCaptionWhite={'Bill and Wil Armstrong in the studio at KPVI TV, Pocatello, '}
          leftCaptionBlack={'Idaho'}
          rightCaptionWhite={`Armstrong and the Board of Helmerich and Payne, the world's largest on-shore drilling company`}
        />
        <BusinessmanSectionFour
          image={SectionFourImage}
          blackCaption={'Armstrong holds press conference with Soviet dissident Mikhail Makarenko, '}
          orangeCaption={'1982'}
          blackLink={'Click to next page: '}
          orangeLink={'Statesman'}
          route="/bio/statesman"
        />
      </Layout>
    </>
  )
}

export default Businessman;